import { appInsights } from '../services/AppInsights';

async function TrackEvent(eventName, action) {
	window.dataLayer.push({
		'event': eventName,
		'action': action
	})
}

async function TrackPageLoad(eventName, pageType) {
	window.dataLayer.push({
		'event': eventName,
		'page_type': pageType
	});
}

async function TrackLeadSubmission() {
	const totalCost = localStorage.getItem("TotalCost");
	const currencyCode = localStorage.getItem("CurrencyCode");
	const villaTotalCost = localStorage.getItem("VillaTotalCost");
	let inventoryResource = localStorage.getItem("InventoryResource");
	const villaName = localStorage.getItem("VillaName");

	if (inventoryResource)
		inventoryResource = inventoryResource.slice(-100);

	window.dataLayer.push({
		'event': 'add_shipping_info',
		'ecommerce': {
			'currency': currencyCode,
			'value': parseFloat(totalCost),
			'items': [
				{
					'item_id': inventoryResource,
					'item_name': "Villa " + villaName,
					'price': parseFloat(villaTotalCost),
					'quantity': 1
				}
			]
		}
		})
}

async function TrackExpiredReservation() {
	window.dataLayer.push({
		'event': "reservation_expired"
		})
}

async function TrackPaymentAttempt() {
	const totalCost = localStorage.getItem("TotalCost");
	const currencyCode = localStorage.getItem("CurrencyCode");
	const villaTotalCost = localStorage.getItem("VillaTotalCost");
	let inventoryResource = localStorage.getItem("InventoryResource");
	const villaName = localStorage.getItem("VillaName");
	let paymentType = null;
	const selectedPaymentTypeOptions = JSON.parse(localStorage.getItem("storedSelectedPaymentOption"));

	if (selectedPaymentTypeOptions != null)
		paymentType = selectedPaymentTypeOptions.applicablePaymentMethods[0];

	if (inventoryResource)
		inventoryResource = inventoryResource.slice(-100);


	window.dataLayer.push({
		'event': "add_payment_info",
		'ecommerce': {
			'currency': currencyCode,
			'value': parseFloat(totalCost),
			'payment_type': paymentType,
			'items': [
				{
					'item_id': inventoryResource,
					'item_name': "Villa " + villaName,
					'price': parseFloat(villaTotalCost),
					'quantity': 1
				}
			]
		}
	});
}

async function TrackSuccessfulPayment() {
	let bookingRef = localStorage.getItem("BookingRef");
	let environment = localStorage.getItem("Environment");
	let totalCost = localStorage.getItem("TotalCost");
	let currencyCode = localStorage.getItem("CurrencyCode");
	let villaTotalCost = localStorage.getItem("VillaTotalCost");
	let inventoryResource = localStorage.getItem("InventoryResource");
	let villaName = localStorage.getItem("VillaName");
	let ecommerceResource = localStorage.getItem("ECommerceBookingLink");

	if (ecommerceResource.includes("villaplustest.com") && environment && environment.toLocaleLowerCase() == "production") {
		appInsights.trackEvent({
			name: 'mismatch in environment test on production',
			properties: {
				bookingRef: bookingRef
			}
		});
	}

	if (ecommerceResource.includes("villaplus.com") && environment && environment.toLocaleLowerCase() != "production") {
		appInsights.trackEvent({
			name: 'mismatch in environment production on test',
			properties: {
				bookingRef: bookingRef
			}
		});
	}

	if (ecommerceResource)
		ecommerceResource = ecommerceResource.slice(-100);

	if (inventoryResource)
		inventoryResource = inventoryResource.slice(-100);

	window.dataLayer.push({
		'event': "purchase",
		'ecommerce': {
			'transaction_id': ecommerceResource,
			'value': parseFloat(totalCost),
			'currency': currencyCode,
			'items': [
				{
					'item_id': inventoryResource,
					'item_name': "Villa " + villaName,
					'price': parseFloat(villaTotalCost),
					'quantity': 1
				}
			]
		}
	});
}

async function TrackFlexibleDurationConfirmation() {
	const duration = localStorage.getItem("Duration");
	if (parseInt(duration) % 7 !== 0) {
		let id = window.innerWidth < 768 ? 211 : 210;
		window._vis_opt_queue.push(function () {
			window._vis_opt_goal_conversion(id);
		});
	}
}

async function TrackInfoBox(label) {

	const isAlreadyAdded = window.dataLayer.find((item) => item.event === "mouse_over_info_boxes" && item.mouse_over_label === label);
	if (typeof(isAlreadyAdded) !== 'undefined') {
		return;
	}

	window.dataLayer.push({
		'event': 'mouse_over_info_boxes',
		'mouse_over_label': label
	});
}

async function TrackPaymentFormEvents(action, content) {
	window.dataLayer.push({
		'event': 'payment_form_interaction',
		'action': action,
		'content': content
	});
}

export default {
	TrackPageLoad,
	TrackLeadSubmission,
	TrackEvent,
	TrackPaymentAttempt,
	TrackSuccessfulPayment,
	TrackExpiredReservation,
	TrackInfoBox,
	TrackFlexibleDurationConfirmation,
	TrackPaymentFormEvents
}