import React from 'react';
import PrivateTransfers from '../components/extras/privateTransfers/PrivateTransfers';
import AccommodationExtras from '../components/extras/accommodationExtras/AccommodationExtras';
import CarHireTransferNote from '../components/extras/CarHireTransferNote';
import Navigation from '../components/shared/navigation/Navigation';
import './extras.css';
import AnalyticsHelper from '../helpers/AnalyticsHelper';
import TotalPrice from '../components/summary/TotalPrice';
import { useState, useEffect } from 'react';
import { dispatch } from 'use-bus';
import { GetFirstAvailableAirport } from '../services/TransfersService';
import Spinner from '../images/spinner.svg';


const Extras = (props) => {
	document.title = props.tabTitle;

	const [showTransfers, setShowTransfers] = useState(false);
	const [defaultTransfer, setDefaultTransfer] = useState(null);
	const [isTransferRequestFinished, setIsTransferRequestFinished] = useState(false);

	useEffect(() => {
		IsTransfersAvailable();
	}, []);

	useEffect(() => {
		AnalyticsHelper.TrackPageLoad("extras_load", "Booking Extras");
	}, [])

	
	function IsTransfersAvailable() {
		const transportHubs = JSON.parse(localStorage.getItem("TransportHubs"));

		if (transportHubs == null || transportHubs.length === 0) {
			setNoTransfers();
			setIsTransferRequestFinished(true);
			return;
		}

		const airportCodes = transportHubs.map(airport => airport.Code);
		GetFirstAvailableAirport(airportCodes)
			.then((response) => {
				if (response && response.data && response.data?.transfers) {
					setDefaultTransfer(response.data);
					setShowTransfers(true);
					console.log("Transfers");
				} else {
					setNoTransfers();
					console.log("No Transfers");
				}
			}).catch((e) => {
				setNoTransfers();
				console.error(e);
			}).finally(() => {
				setIsTransferRequestFinished(true);
			});
			
	}

	function setNoTransfers() {
		sessionStorage.setItem("TransfersAvailable", false);
		dispatch("noTransfers");
		dispatch("transfersSet");
	}

	return (
		(isTransferRequestFinished &&
			<>
				{showTransfers &&
					<div>
						<PrivateTransfers defaultTransfers={defaultTransfer}/>
					</div>
				}

				<AccommodationExtras />

				<CarHireTransferNote  />

				<div className="row justify-content-between bottom-navigation d-flex d-md-none" style={{ bottom: "0" }}>
					<div className="col-auto" style={{ alignContent: "center" }}>
						<TotalPrice />
					</div>
					<Navigation buttonKey="EXTRAS_FORWARD_BUTTON" pathToNextPage="/contactdetails" completedStep="extras" />
				</div>
				<div className="d-none d-md-flex">
					<TotalPrice />
					<div className="mt-0 mb-3">
						<Navigation buttonKey="EXTRAS_FORWARD_BUTTON" pathToNextPage="/contactdetails" completedStep="extras" />
					</div>
				</div>
			</>
		) ||
		<>
			<img alt="Loading" src={Spinner} className="d-flex mx-auto" style={{ width: '15%' }} />
		</>
	)
}

export default Extras;