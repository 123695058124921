import axios from 'axios';
import cookieHelper from '../helpers/CookieHelper';
import AppInsightsHelper from '../helpers/AppInsightsHelper';

async function GetCheckoutStart() {
	let token = localStorage.getItem("ECommerceToken");
	let commitStartResource = localStorage.getItem("CommitStartResource");
	let currencyCode = localStorage.getItem("CurrencyCode");
	let selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");
	let appliedVoucher = localStorage.getItem("AppliedVoucher");

	let appliedVoucherCode = "";
	let appliedVoucherBookingRef = "";
	if (appliedVoucher != null && appliedVoucher !== "") {
		appliedVoucherCode = JSON.parse(appliedVoucher).Code;
		appliedVoucherBookingRef = JSON.parse(appliedVoucher).BookingRef;
	}

	const accommodationBookingResource = localStorage.getItem("AccommodationBookingResource");
	const bookingRef = localStorage.getItem("BookingRef");
	const guestCount = localStorage.getItem("Guests");
	let unsupportedAcquirers = [];

	if (!selectedLanguage)
		selectedLanguage = "en";

	if (localStorage.getItem("UnsupportedAcquirers") != null) {
		unsupportedAcquirers = JSON.parse(localStorage.getItem("UnsupportedAcquirers"));
	}

	if (appliedVoucherBookingRef !== bookingRef) {
		appliedVoucherCode = "";
		localStorage.removeItem("AppliedVoucher");
	}

	AppInsightsHelper.logCommitStart();
	return await axios.post("/api/payment/GetCommitStart", {
		commitStartResource: commitStartResource,
		currencyCode: currencyCode,
		AccommodationBookingResource: accommodationBookingResource,
		bookingRef: bookingRef,
		guestCount: JSON.parse(guestCount),
		unsupportedAcquirers: unsupportedAcquirers,
		translationLanguage: selectedLanguage.toLowerCase(),
		appliedVoucherCode: appliedVoucherCode
	}, { 'headers': { 'token': token } })

}


async function SendSuitabilityEmail() {
	const bookingReference = localStorage.getItem("BookingRef");
	const leadName = localStorage.getItem("LeadFullName");
	const lastName = localStorage.getItem("LeadLastName");
	const leadEmailId = localStorage.getItem("LeadEmail");
	const documentLink = localStorage.getItem("DocumentLink");
	const historyLink = localStorage.getItem("HistoryLink");
	let selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");

	if (selectedLanguage == "en-US")
		selectedLanguage = "en";

	return await axios.post("/api/payment/SendVillaSuitabilityRequestEmail",
		{
			bookingRef: bookingReference,
			leadEmail: leadEmailId,
			leadFullName: leadName,
			leadLastName: lastName,
			documentLink: documentLink,
			historyLink: historyLink,
			language: selectedLanguage
		});
}


export { GetCheckoutStart, SendSuitabilityEmail };