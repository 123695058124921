import React from 'react';
import { useTranslation } from 'react-i18next';
import cookieHelper from '../../helpers/CookieHelper';
import formatHelper from '../../helpers/FormatHelper';
import Popup from 'reactjs-popup';
import ddLogo from '../../images/direct-debit.svg';
import AnalyticsHelper from '../../helpers/AnalyticsHelper';

const DirectDebit = () => {
    const { t } = useTranslation();
    let selectedLanguage = cookieHelper.getCookie("SelectedLanguageOption");
    let currencyCode = localStorage.getItem("CurrencyCode");
    let balanceDueDate = localStorage.getItem("BalanceDueDate");

    if (!selectedLanguage)
        selectedLanguage = "en";
    let nonRefundableTermsLink = localStorage.getItem("NonRefundableTermsLink");

    const depositAmount = currencyCode == "GBP" ? localStorage.getItem("DepositAmount") : currencyCode == "EUR" ? localStorage.getItem("DepositAmountInEuro") : localStorage.getItem("DepositAmountInUSD");
    const savingsAmount = currencyCode == "GBP" ? localStorage.getItem("SavingsInGBP") : currencyCode == "EUR" ? localStorage.getItem("SavingsInEUR") : localStorage.getItem("SavingsInUSD");

    let tooltip = (
        <div>
            <div className="mb-4 mt-3 ml-4">
                Pay {formatHelper.formatPrice(depositAmount)} deposit today, then choose:
            </div>
            <div className="row ml-3 mr-3">
                <div className="col">
                    <div className="row m-0">
                        <div className="col-12 p-0 mb-3">
                            • Set up Direct Debit instalments and pay monthly at no extra cost
                        </div>
                        <div className="col-12 p-0">
                            <img loading="lazy" className="img-fluid" src={ddLogo} />
                        </div>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="row m-0">
                        <div className="col-12 p-0">
                            <div style={{ borderLeft: "solid 1px #757575", height: "10px", marginLeft: "50%" }}></div>
                        </div>
                        <div className="col-12 p-0 text-center" style={{ fontWeight: "800" }}>
                            OR
                        </div>
                        <div className="col-12 p-0">
                            <div style={{ borderLeft: "solid 1px #757575", height: "70px", marginLeft: "50%" }}></div>
                        </div>
                    </div>
                </div>
                <div className="col">
                    • Pay the balance in full by {balanceDueDate}
                </div>
            </div>
        </div>);
    let depositSection;
    if (depositAmount !== "" && depositAmount != undefined && depositAmount != null && (nonRefundableTermsLink == undefined || nonRefundableTermsLink == null || nonRefundableTermsLink == ""))
    {
        depositSection = <div className="summary-text-section">
            <div className="d-flex pb-2">
                <span className="checkMarker icon-checkMarker"></span>
                <span>{t("DEPOSIT_TEXT")}</span>
                <span><span className="ml-1" style={{ fontWeight: "800" }}>{formatHelper.formatPrice(depositAmount)}&nbsp;</span>{t("TODAY")}</span>
            </div>
            {
                selectedLanguage == 'en' && currencyCode == 'GBP' &&
                <div>
                    <div className="pb-2">
                        <div className="checkMarker icon-checkMarker" style={{ float: "left" }}></div>
                        <div className="col popupWrapper">
                            Option to pay balance by&nbsp;
                                <Popup className="ddTooltip"
                                    trigger={
                                        <span>
                                            <img loading="lazy" className="img-fluid" src={ddLogo} style={{ borderBottom: "dotted 2px #cacaca", paddingBottom: "4px", cursor: "pointer" }} />
                                        </span>
                                    }
                                    onOpen={() => AnalyticsHelper.TrackInfoBox("Direct Debit")}
                                    position='top center'
                                    on={['hover']}
                                    arrow={true}>
                                    {tooltip}
                                </Popup>
                                <div className="col" style={{ paddingLeft: "18px"}}>
                                    instalments
                                </div>
                        </div>
                    </div>
                    <div className="d-flex pb-2">
                        <span className="checkMarker icon-checkMarker"></span>
                        <span>Flexible booking policy</span>
                    </div>
                </div>
            }
        </div>
    }
    else if (depositAmount !== "" && depositAmount != undefined && depositAmount != null) {
        depositSection = <div className="summary-text-section">
            <div className="d-flex pb-2">
                <span className="checkMarker icon-checkMarker"></span>
                <span>
                    {t("SAVE_BENEFIT_OFF_FLEXIBLE_PRICE").replace("{BENEFIT}", formatHelper.formatPrice(savingsAmount))}
                </span>
            </div>
            <div>
                <div className="pb-2">
                    <div className="checkMarker icon-checkMarker" style={{ float: "left" }}></div>
                    <div className="col popupWrapper">
                        {t("PAY_FULL_ACCOMMODATION_AMOUNT_TODAY")}
                    </div>
                </div>
                <div className="d-flex pb-2">
                    <span className="checkMarker icon-checkMarker"></span>
                    <span>{t("NO_CHANGES")}</span>
                </div>
            </div>
        </div>
    };
    let content = (
         depositSection 
	);

	return content;
}

export default DirectDebit;
